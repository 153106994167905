import { log } from './log';

// Airbrake is loaded early in the layout and set on the window
// See: app/assets/javascripts/shared/_airbrake-setup.js.erb
// This module is a shim to leave the door open for migrating Packs at point in the future

const airbrake = window.airbrake || {
  notify: (...args) =>
    log('[AIRBRAKE] Could not find window.airbrake.', ...args),
};

export default airbrake;
