import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'shared/~bravely/variables/_colors.module.scss';
import styles from './styles.module.scss';

const LoadingSpinner = ({
  size = 30,
  borderWidth = 5,
  dark = false,
  altText = 'Loading...',
}) => {
  const baseColor = dark ? colors.blueDarkest : colors.whitePure;
  const sizeStyles = {
    borderWidth: `${borderWidth}px`,
    width: `${size}px`,
    height: `${size}px`,
    borderColor: `${baseColor}33`, // #RRGGBBAA equivalent rgba(r,g,b,0.2)
    borderLeftColor: baseColor,
  };

  const { t } = useTranslation();

  return (
    <div className={styles.Loader} style={sizeStyles} title={altText}>
      <span className={styles.LoaderLabel}>{t(altText)}</span>
    </div>
  );
};

export default LoadingSpinner;
