// Mixpanel JS Documentation
// https://developer.mixpanel.com/docs/javascript-full-api-reference

import { isEmployee } from 'utils/role';
import endpoints from 'utils/api/endpoints';
import { decamelizeKeys } from 'humps';

/* eslint-disable camelcase */
export const mixpanelEvents = {
  activation: {
    VIEW_FORM: 'View Activation Form Page',
    COMPLETE: 'Complete Activation',
  },
  booking: {
    VIEW_TOPIC: 'View Session Topic Page',
    SELECT_TOPIC: 'Select Session Topic',
    SELECT_DESCRIPTION: 'Select Session Description',
    VIEW_LANGUAGE: 'View Session Language Page',
    SELECT_LANGUAGE: 'Select Session Language',
    SUBMIT_SESSION_LANGUAGE: 'Submit Session Language',
    SELECT_IDENTITY_CATEGORY: 'Select Session Identity Category',
    SUBMIT_IDENTITY_CATEGORIES: 'Submit Session Identity Categories',
    SELECT_ETHNICITY: 'Select Session Identity Ethnicity',
    SELECT_GENDER: 'Select Session Identity Gender',
    SELECT_ORIENTATION: 'Select Session Identity Orientation',
    SELECT_PRO: 'Select Session Pro',
    SELECT_TIMEZONE: 'Select Session Timezone',
    SELECT_DATE: 'Select Session Date',
    SELECT_TIME: 'Select Session Time',
    SUBMIT: 'Submit Session Scheduling Options',
    CONFIRM: 'Click Confirm & Book',
    ZERO_AVAILABILITY: 'Zero Availability Returned',
    NO_PREFERENCE: 'No Preference',
    CLICK_EDIT_PREFERENCES: 'Click Edit Preferences Link',
    SELECT_VIDEO_SESSION: 'Select Video Session',
  },
  demographics: {
    CLICK_HOMEPAGE_CARD: 'Click Homepage Profile Card',
    VIEW_ACCOUNT: 'View Account Page',
    VIEW_PROFILE: 'View Initial Profile Set-up Page',
    VIEW_FIRST_NAME: 'View First Name Screen',
    VIEW_ETHNICITY: 'View Ethnicity',
    CHOOSE_ETHNICITY: 'Choose Ethnicity',
    VIEW_IMPORTANT: 'View Why Is This Important',
    VIEW_GENDER: 'View Gender',
    CHOOSE_GENDER: 'Choose Gender',
    VIEW_PRONOUNS: 'View Pronouns',
    CHOOSE_PRONOUNS: 'Choose Pronouns',
    VIEW_LGBTQ: 'View LGBTQ',
    CHOOSE_LGBTQ: 'Choose LGBTQ',
    COMPLETED: 'Completed Demographics Survey',
  },
  reschedule: {
    ADD_TO_CALENDAR: 'Add Session To Calendar',
    CLICK_RESCHEDULE: 'Click Reschedule Session',
    CLICK_NEVERMIND: 'Click Nevermind',
    CLICK_SAVE: 'Click Save (Reschedule Complete)',
    CLICK_CANCEL: 'Click Cancel This Session',
    CLICK_KEEP: "Click I'll Keep It",
    COMPLETE_CANCEL: 'Click Yes, Cancel (Completed Cancellation)',
  },
  session: {
    VIEW_PREP_PAGE: 'View Initial Session Prep Page',
    VIEW_ALL: 'View Your Sessions',
    JOIN_CALL: 'Click Join Call',
    LEAVE_CALL: 'Leave The Call',
    OPEN_MODAL: 'Open Call Questions Modal',
  },
  general: {
    DAILY_MOMENTS_SUBSCRIBE: 'Click Daily Moments Sign Up',
    OPEN_INTERCOM: 'Open Intercom Message Box',
    VIEW_HOMEPAGE: 'View Homepage',
    CLICK_HOME_LINK: 'Click Home Link',
  },
  library: {
    VIEW_MAIN_PAGE: 'View Content Library Main Page',
    CLICK_CONTENT_ITEM: 'Click the content box',
    SEARCH_CONTENT_LIBRARY: 'Search the content library',
    CLICK_SEE_MORE: `Click "See more"`,
    CLICK_VIEW_ALL_LIBRARY_CONTENT: `Click "View All Library Content"`,
    CLICK_MORE_FEATURED_COLLECTIONS: `Click "More Feature Collections"`,
    VIDEO_MODAL: {
      CLICK_BOOK_A_SESSION: `Click "Book a Session" in video modal content`,
    },
  },
  needsAndDemography: {
    VIEW_BASIC_PROFILE_PAGE: 'View Basic Profile Page',
    VIEW_DEMOGRAPHIC_INFO_PAGE: 'View Demographic Info Page',
    VIEW_NEEDS_INTRO_PAGE: 'View Needs Intro Page',
    VIEW_PAGE: (page, val) => Boolean(page && val) && `View ${page} - ${val}`,
    SELECT_NEEDS: 'Select Needs',
    UNSELECT_NEEDS: 'Unselect Needs',
    CLICK_SAVE_AND_EXIT_BTN: 'Click Save & Exit Button',
    CLICK_BACK_BTN: 'Click Back Button',
    CLICK_SEE_YOUR_CURRENT_NEEDS: 'Click "see your current needs"',
    VIEW_PROFILE_SUMMARY_PAGE: 'View Profile Summary Page',
    CLICK_ASSESS_NEEDS: 'Click Assess Your Needs CTA',
  },
  referACoWorker: {
    SUBMIT: 'Submit Refer a Co-Worker Form',
    VIEW_FORM: 'View Refer a Co-Worker Form',
  },
  webinars: {
    VIEW_WEBINAR_LANDING_PAGE: 'View Webinar Landing Page',
    CLICK_WEBINAR_CTA: 'Click Webinar Landing Page CTA',
    VIEW_WEBINAR_CONFIRMATION_PAGE: 'View Webinar Confirmation Page',
    VIEW_WEBINAR_CONTENT_PAGE: 'View Webinar Content Page',
    CLICK_ADD_TO_CALENDER:
      'Click "Add to Calendar" on Webinar Confirmation Page',
    CLICK_BOOK_SESSION: 'Click Sidebar CTA on Watch Page',
    CLICK_DOWNLOAD_WEBINAR_PRESENTATION:
      'Click "Download Webinar Presentation" on Webinar Watch page',
    CLICK_WEBINAR_CONFIRMATION_CTA: 'Click Webinar Confirmation CTA',
  },
  newEmployeeDashboard: {
    VIEW_DASHBOARD: 'View Dashboard',
    CLICK_DASHBOARD_BOOK_SESSION_CTA:
      'Click Dashboard "Book a Session" CTA Button',
    CLICK_DASHBOARD_CARD: 'Click Dashboard Card',
    CLICK_TODO_FROM_TODO_PAGE: 'Click Todo from Todos Page',
    VIEW_TODO_PAGE: 'View Todos Page',
    CLICK_DASHBOARD_NAVIGATION_ITEM: 'Click Dashboard Navigation Item',
  },
};

// Basic Mixpanel method
// for triggering tracking events
export const mixpanelTrack = (eventName, additionalData = {}) => {
  if (!eventName) return;

  if (isEmployee()) {
    endpoints.trackEvent({
      eventTitle: eventName,
      otherProperties: additionalData,
    });
  } else {
    if (!window.mixpanel) {
      window.airbrake &&
        window.airbrake.notify('Mixpanel client not available');
      return;
    }

    window.mixpanel.track(eventName, decamelizeKeys(additionalData), {
      send_immediately: true,
    });
  }
};

// Use to specifically track events
// triggered by <a> tags
export const mixpanelTrackLink = (
  selector = '',
  eventName = '',
  additionalData
) => {
  if (!window.mixpanel) {
    return;
  }

  window.mixpanel.track_links(
    selector,
    eventName,
    decamelizeKeys(additionalData) || null
  );
};

// Registers Super Properties,
// which will be sent with every
// subsequent event
export const mixpanelRegister = (props = {}) => {
  if (!window.mixpanel || !Object.keys(props).length) {
    return;
  }

  window.mixpanel.register(props);
};

export const mixpanelRegisterOnce = (props = {}, override = 'None') => {
  if (!window.mixpanel || !Object.keys(props).length) {
    return;
  }

  window.mixpanel.register_once(props, override);
};

// Identify is called on login
// to merge any pre-login user interactions
// with a logged-in user
export const mixpanelIdentify = (id = '') => {
  if (!window.mixpanel || !id) {
    return;
  }

  window.mixpanel.identify(id);
};

// people.set() is called after identify() to attach
// user properties to a user and create a
// mixpanel user object
export const mixpanelSetUser = (props = {}) => {
  if (
    !window.mixpanel ||
    !window.mixpanel.people ||
    !Object.keys(props).length
  ) {
    return;
  }

  window.mixpanel.people.set(props);
};

export const mixpanelReset = () => {
  if (!window.mixpanel) {
    return;
  }

  window.mixpanel.reset();
};

export const parseDemographics = (choices) => {
  const selectedValue = Object.keys(choices.selectedValues)[0] || '';
  const inputValue = choices.freeformValue || '';
  const multipleValues = selectedValue && inputValue;

  return `${selectedValue}${(multipleValues && ', ') || ''}${inputValue}`;
};

export const cleanQueryParams = (params) => {
  return Object.keys(params)
    .filter((key) => params[key])
    .reduce((object, key) => ({ ...object, [key]: params[key] }), {});
};
