import React from 'react';
import MediaQuery from 'react-responsive';
import UAParser from 'ua-parser-js';

export const MOBILE_MAX = 767;
export const DESKTOP_MIN = 768;

export const Mobile = (props) => (
  <MediaQuery {...props} maxWidth={MOBILE_MAX} />
);
export const Desktop = (props) => (
  <MediaQuery {...props} minWidth={DESKTOP_MIN} />
);

export const isDesktop = () =>
  matchMedia(`(min-width: ${DESKTOP_MIN}px)`).matches;

export const isMobileClient = () => {
  const parser = new UAParser();
  const device = parser.getDevice().type;
  return device === 'mobile' || device === 'tablet';
};
