import BravelyData from './data';

const ANONYMOUS = 'anonymous';
const EMPLOYEE = 'employee';
const ORGANIZATION = 'organization';
const PRO = 'pro';
const ADMIN = 'admin';

export const ROLES = {
  ANONYMOUS,
  EMPLOYEE,
  ORGANIZATION,
  PRO,
  ADMIN,
};

export const isAnonymous = () => !BravelyData('isLoggedIn');
export const isEmployee = () => !!BravelyData('isEmployee');
export const isOrganization = () => !!BravelyData('isOrganization');
export const isPro = () => !!BravelyData('isPro');
export const isAdmin = () => !!BravelyData('isAdmin');

export function currentRole() {
  switch (true) {
    case isAnonymous():
      return ROLES.ANONYMOUS;
    case isEmployee():
      return ROLES.EMPLOYEE;
    case isOrganization():
      return ROLES.ORGANIZATION;
    case isPro():
      return ROLES.PRO;
    case isAdmin():
      return ROLES.ADMIN;
  }
}
