import React from 'react';
import PropTypes from 'prop-types';

export default function Icon({ icon, className, ...props }) {
  return (
    <span
      className={className || null}
      dangerouslySetInnerHTML={{ __html: icon }}
      {...props}
    />
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};
