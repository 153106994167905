import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

export const TIMEOUT = 5000;

export default class FlashMessage extends React.Component {
  static defaultProps = {
    timeout: TIMEOUT,
  };

  static propTypes = {
    timeout: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      flashHidden: false,
    };

    this.hideFlash = this.hideFlash.bind(this);
  }

  componentDidMount() {
    this.props.timeout && this.startTimer();
  }

  componentWillUnmount() {
    this.props.timeout && window.clearTimeout(this.timer);
  }

  hideFlash() {
    this.setState({ flashHidden: true }, window.clearTimeout(this.timer));
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.setState({
        flashHidden: true,
      });
    }, this.props.timeout);
  }

  render() {
    return (
      <div className={styles.flashContainer}>
        <div
          className={cx(styles.flashMessage, cx(styles[this.props.type]), {
            [styles.hidden]: this.state.flashHidden,
          })}
        >
          {this.props.children}
          <span className={styles.flashDismiss} onClick={this.hideFlash}></span>
        </div>
      </div>
    );
  }
}
